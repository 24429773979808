.json-schema-2020-12-keyword--dependentRequired {
  & > ul {
    display: inline-block;
    padding: 0;
    margin: 0;

    li {
      display: inline;
      list-style-type: none;
    }
  }
}
