.no__highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.glowing_text {
  text-align: center;
  -webkit-animation: glowing_text 1s ease-in-out infinite alternate;
  -moz-animation: glowing_text 1s ease-in-out infinite alternate;
  animation: glowing_text 1s ease-in-out infinite alternate;
}

/* Invisible scroll */
.scrollbar-hidden {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.scrollbar-hidden::-webkit-scrollbar {
  width: 0px;
}

.scrollbar-hidden::-webkit-scrollbar-thumb {
  background-color: transparent;
}

@keyframes glowing_text {
  from {
    color: #f96161;
  }
  to {
    color: #950707;
  }
}

.CodeMirror {
  height: 100% !important;
  background-color: lightgray;
}